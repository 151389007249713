import { lazy, useRef, useState } from 'react';
import { Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconHintSmall } from '@medifind/icons';
import styles from './What.module.scss';
const Tooltip = lazy(() => import('./tooltip/Tooltip'));

const What = ({
  children,
  container,
  onClose,
  closeWhenClickingOut,
  closeIcon,
  actionButton,
  icon,
  iconId,
  classes = {},
  position,
  showProp = false,
  showPrev,
  showNext,
  hideWhat = false,
  hideHeading = false,
}) => {
  const ref = useRef(null);
  const [hover, setHover] = useState(false);
  const [show, setShow] = useState(showProp);

  const handleMouseover = () => {
    setHover(true);
  };

  const handleMouseout = () => {
    if (!show) {
      setHover(false);
    }
  };

  const handleWhatClick = (e) => {
    e.stopPropagation();
    setShow(!show);
  };

  return (
    <>
      {!hideWhat && (
        <span
          onFocus={handleMouseover}
          onMouseOver={handleMouseover}
          onBlur={handleMouseout}
          onMouseOut={handleMouseout}
          className={classNames(styles['what'], classes['what'])}
        >
          <div ref={ref} role="presentation" onMouseDown={handleWhatClick}>
            <Suspense fallback={<div className="fallback" />}>
              {show && (
                <Tooltip
                  onClose={(status, param) => {
                    setShow(false);
                    setHover(false);
                    if (status) {
                      onClose(status, param);
                    }
                  }}
                  closeWhenClickingOut={closeWhenClickingOut}
                  iconId={iconId}
                  closeIcon={closeIcon}
                  actionButton={actionButton}
                  show={show}
                  position={position}
                  showPrev={showPrev}
                  showNext={showNext}
                  classes={{ tooltipContainer: classes.tooltipContainer }}
                >
                  {hideHeading ? null : <h3 className={styles.title}>What's this?</h3>}
                  <div className={styles.body}>{children}</div>
                </Tooltip>
              )}
            </Suspense>
            {icon ? icon : <IconHintSmall color={hover ? 'blue' : 'lightGreyText'} />}
          </div>
        </span>
      )}
    </>
  );
};

What.propTypes = {
  classes: PropTypes.shape({
    what: PropTypes.string,
  }),
  showProp: PropTypes.bool,
  hideWhat: PropTypes.bool,
};
export { What as default, What };
