import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IconLinkedin } from '@medifind/icons';
import { IconGoogleLogin } from '@medifind/icons';
import { facebookLoginCallback, googleLoginCallback, linkedInLoginCallback } from '@medifind/interface';
import { generateURL, useRouter } from '@medifind/router';
import buttonsStyles from '@medifind/styles/components/buttons.module.scss';
import { eventDispatch } from '@medifind/utils';
import { isMobile, pathShouldRedirect } from '@medifind/utils';
import { login } from '@medifind/zustand';
import { logEventGA4 } from '@medifind/zustand';
import styles from './SocialLogin.module.scss';

const SocialLoginButton = ({ name = '', onClick, icon }) => {
  return (
    <button type="button" className={classNames(buttonsStyles['btn'], buttonsStyles['btn--social'])} onClick={onClick}>
      {icon && icon}
      Continue with {name}
    </button>
  );
};

const SocialLogin = ({
  title,
  location,
  defaultSocialLogin = '',
  email = '',
  type,
  displaySocialLogin,
  hasPMAccount,
  campaign,
  onSignIn,
}) => {
  const intervalRef = useRef(null);

  const router = useRouter();
  const [windowObjectReference, setWindowObjectReference] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  const openSocialLoginPopUpWindow = (uri, name) => {
    const redirectUrl = `${process.env.NX_API_HOST}` + uri;
    const w = 600;
    const h = 700;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    const strWindowFeatures = isMobile
      ? ''
      : 'toolbar=no, menubar=no, scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left;
    const openIn = isMobile ? '_blank' : name;
    if (!windowObjectReference || windowObjectReference.closed || previousUrl !== redirectUrl) {
      try {
        // Attempt to close old window
        if (windowObjectReference && !windowObjectReference.closed) windowObjectReference.close();
      } catch (e) {}
      setPreviousUrl(redirectUrl);
      const frame = window.open(redirectUrl, openIn, strWindowFeatures);
      setWindowObjectReference(frame);
      if (frame.focus) frame.focus();
    }
  };
  const cleanUp = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (windowObjectReference && !windowObjectReference.closed) windowObjectReference.close();
    if (windowObjectReference) window.localStorage?.setItem('oath_window_close', `${new Date().getTime()}`);

    try {
      window.localStorage?.removeItem('oath_response');
    } catch (e) {}
    // This will cause listeners and timeout to clear
    if (windowObjectReference) setWindowObjectReference(null);
  };
  const oathLogin = async ({ source, randomTimestamp, ...params }) => {
    let data;
    if (source === 'facebook') {
      data = { source: 'Facebook', ...(await facebookLoginCallback(params).catch(({ message }) => ({ error: 'Login failed' }))) };
    } else if (source === 'google') {
      data = { source: 'Google', ...(await googleLoginCallback(params).catch(({ message }) => ({ error: 'Login failed' }))) };
    } else if (source === 'linkedin') {
      data = { source: 'LinkedIn', ...(await linkedInLoginCallback(params).catch(({ message }) => ({ error: 'Login failed' }))) };
    }
    if (!data || data.error) {
      logEventGA4('exception', { type: 'social Login', description: 'Social Login Callback Error', fatal: false });
      return; // TODO Handle error
    }
    if (data.newUser) {
      logEventGA4('sign_up', { location, type: campaign, method: data.source });
    } else {
      logEventGA4('login', { type: campaign, method: data.source });
    }

    login(data.token)
      .then(() => {
        eventDispatch('closeAuth');
        if (type === 'profile-management' && !hasPMAccount) {
          displaySocialLogin(false);
        } else if (pathShouldRedirect(router.location.pathname)) {
          router.history.push('/profile/dashboard');
        } else {
          onSignIn && onSignIn();
        }
      })
      .catch(() => {
        logEventGA4('exception', { type: 'social Login', description: 'Social Login API Error', fatal: false });
      });
    cleanUp();
  };

  useEffect(() => {
    if (windowObjectReference) {
      // Message via window.opener
      const receiveMessage = ({ data }) => {
        if (data?.code && ['google', 'facebook'].includes(data?.source)) {
          window.removeEventListener('message', receiveMessage);
          oathLogin(data);
        }
      };

      // Message via localstorage
      const checkLocalStorage = ({ key, newValue }) => {
        if (key === 'oath_response' && newValue) {
          try {
            const data = JSON.parse(newValue);
            if (data.code) {
              window.removeEventListener('storage', checkLocalStorage);
              oathLogin(data);
            }
          } catch (e) {
            console.error(e);
          }
        }
      };

      // Fallback to checking the url every bit
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = windowObjectReference.location.href;
          if (currentUrl) {
            const params = new URL(currentUrl).searchParams;

            if (params.get('code')) {
              clearInterval(intervalRef.current);
              oathLogin(params);
            }
          }
        } catch (e) {}
      }, 700);

      // Register event listenters after window was opened
      window.addEventListener('storage', checkLocalStorage);
      window.addEventListener('message', receiveMessage);
      return () => {
        window.removeEventListener('storage', checkLocalStorage);
        window.removeEventListener('message', receiveMessage);
        clearInterval(intervalRef.current);
      };
    }
  }, [windowObjectReference]);

  useEffect(() => {
    if (defaultSocialLogin === 'Google') {
      openSocialLoginPopUpWindow(generateURL('/user/google/sign-in', { email, campaign }), 'Google');
    } else if (defaultSocialLogin === 'Facebook') {
      openSocialLoginPopUpWindow(generateURL('/user/facebook/sign-in', { email, campaign }), 'Facebook');
    } else if (defaultSocialLogin === 'LinkedIn') {
      openSocialLoginPopUpWindow(generateURL('/user/linkedin/sign-in', { email, campaign }), 'LinkedIn');
    }
  }, [defaultSocialLogin]);

  return (
    <div className={classNames(styles['social-media'])}>
      {title && <h3>{title}</h3>}

      <div className={classNames(styles['social-media'], styles['social-media__buttons'])}>
        {type === 'profile-management' ? (
          <SocialLoginButton
            name="LinkedIn"
            icon={<IconLinkedin />}
            onClick={() => openSocialLoginPopUpWindow(generateURL('/user/linkedin/sign-in', { campaign }), 'LinkedIn')}
          />
        ) : null}
        <SocialLoginButton
          name="Google"
          icon={<IconGoogleLogin />}
          onClick={() => openSocialLoginPopUpWindow(generateURL('/user/google/sign-in', { campaign }), 'Google')}
        />
        {/* {type === 'sign-in' && (
          <SocialLoginButton
            name="Facebook"
            icon={<IconFacebookLogin />}
            onClick={() => openSocialLoginPopUpWindow(generateURL('/user/facebook/sign-in', { campaign }), 'Facebook')}
          />
        )} */}
        {type !== 'profile-management' ? (
          <SocialLoginButton
            name="LinkedIn"
            icon={<IconLinkedin />}
            onClick={() => openSocialLoginPopUpWindow(generateURL('/user/linkedin/sign-in', { campaign }), 'LinkedIn')}
          />
        ) : null}
      </div>

      <div className={styles['social-media__or']}>or</div>
    </div>
  );
};

export { SocialLogin as default, SocialLogin };
