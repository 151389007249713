import * as yup from 'yup';

// Note async calls in yup are called on change for ANY fields in the form, wrapping is in reCache should always be done
yup.addMethod(yup.string, `required`, function (message, field) {
  return this.nullable(true).test({
    name: 'required',
    message,
    test: (value) => {
      return !!value;
    },
  });
});

yup.addMethod(yup.mixed, `hasValidDate`, function (message, field) {
  return this.test({
    name: 'hasValidDate',
    message,
    test: (value, { parent: { dobYear, dobMonth, dobDay } }) => {
      if (!dobYear || !dobMonth || !dobDay) return true;
      if (field === 'dobYear') {
        const date = new Date(dobYear, 0, 1);
        return date.getFullYear() === dobYear;
      } else if (field === 'dobMonth') {
        const date = new Date(1969, dobMonth - 1, 1);
        return date.getMonth() === dobMonth - 1;
      } else if (field === 'dobDay') {
        const year = dobYear < 1900 ? 1900 : dobYear;
        const date = new Date(year, dobMonth - 1, dobDay);
        return date.getFullYear() === year && date.getMonth() === dobMonth - 1 && date.getDate() === dobDay;
      }
    },
  });
});

yup.addMethod(yup.mixed, `hasPastDate`, function (message, field) {
  return this.test({
    name: 'hasPastDate',
    message,
    test: (value, { parent: { dobYear, dobMonth, dobDay } }) => {
      const dob = new Date(dobYear, dobMonth - 1, dobDay);
      const isDateValid = (date) => date.getFullYear() === dobYear && date.getMonth() === dobMonth - 1 && date.getDate() === dobDay;
      const today = new Date();
      if (!isNaN(dob) && isDateValid(dob) && dob > today) {
        return false;
      }
      return true;
    },
  });
});

yup.addMethod(yup.mixed, `dateHasRequiredFields`, function (message, field) {
  return this.test({
    name: 'dateHasRequiredFields',
    message,
    test: (value, { parent: { dobYear, dobMonth, dobDay } }) => {
      if (value) return true;
      if ((dobYear || dobMonth || dobDay) && (!dobYear || !dobMonth || !dobDay)) return false;
      return true;
    },
  });
});

yup.addMethod(yup.mixed, `isAdult`, function (message, field) {
  return this.test({
    name: 'isAdult',
    message,
    test: (value, { parent: { dobYear, dobMonth, dobDay } }) => {
      const dob = new Date(dobYear, dobMonth - 1, dobDay);
      const today = new Date();
      return new Date(today - dob).getFullYear() - 1970 >= 18;
    },
  });
});

// fixes NaN values: https://github.com/jquense/yup/issues/1330
yup.addMethod(yup.number, 'transformNaNtoNull', function () {
  return this.nullable(true).transform((_, val) => {
    return val ? Number(val) : null;
  });
});

yup.addMethod(yup.string, 'transformEmptyStringToNull', function () {
  return this.nullable(true).transform((_, val) => {
    return (typeof val === 'string' ? val.trim() : val) || null;
  });
});

yup.addMethod(yup.mixed, 'transformEmptyStringToNull', function () {
  return this.nullable(true).transform((_, val) => {
    return (typeof val === 'string' ? val.trim() : val) || null;
  });
});

yup.addMethod(yup.string, 'trim', function () {
  return this.transform((_, val) => {
    return typeof val === 'string' ? val?.trim() ?? null : val;
  });
});

yup.addMethod(yup.mixed, 'isArrayElementEmpty', function () {
  return this.test({
    name: 'isArrayEmpty',
    message: 'Element cannot be empty',
    test: function (val, { parent }) {
      if (parent.length === 1) return true;
      if (!val) return false;
      if (Array.isArray(val) && val.some((d) => d !== '' && d != null)) return true;
      if (val instanceof Object && !Object.values(val).some((d) => d !== '' && d != null)) return false;
      return true;
    },
  });
});

yup.addMethod(yup.string, `isValidNumber`, function () {
  return this.test({
    name: 'isValidNumber',
    message: '${path} must be a number',
    test: function (val) {
      return !(val?.length > 0) || !isNaN(val);
    },
  });
});

yup.addMethod(yup.string, `notExist`, function () {
  return this.test({
    name: 'notExist',
    message: '${path} must not exist',
    test: function (val) {
      return !(val?.length > 0 || !!val);
    },
  });
});

yup.addMethod(yup.mixed, `notExist`, function () {
  return this.test({
    name: 'notExist',
    message: '${path} must not exist',
    test: function (val) {
      return !(val?.length > 0 || !!val);
    },
  });
});

yup.addMethod(yup.string, `stringMinLetterAmount`, function (message, amountOfLetters) {
  return this.test({
    name: 'stringMinLetterAmount',
    message,
    test: function (val) {
      if (val) {
        return val.match(/[a-zA-Z]/g)?.length >= amountOfLetters;
      } else {
        return true;
      }
    },
  });
});

const dobDateSchema = (required, checkIfAdult) => {
  return yup.object({
    dobMonth: yup
      .number()
      .transformNaNtoNull()
      .min(1, 'Please Select a Value')
      .max(12, 'Please Select a Value')
      .label('Month')
      .hasPastDate('The date must be in the past', 'dobMonth')
      .hasValidDate('Date is invalid', 'dobMonth')
      .dateHasRequiredFields('Required', 'dobMonth')
      .when([], {
        is: () => required == true,
        then: (schema) => schema.required(),
      })
      .when([], {
        is: () => checkIfAdult === true,
        then: (schema) => schema.isAdult('', 'dobMonth'),
      }),
    dobDay: yup
      .number()
      .transformNaNtoNull()
      .min(1, 'Date is invalid')
      .max(31, 'Date is invalid')
      .label('Day')
      .hasPastDate('The date must be in the past', 'dobDay')
      .hasValidDate('Date is invalid', 'dobDay')
      .dateHasRequiredFields('Required', 'dobDay')
      .when([], {
        is: () => required === true,
        then: (schema) => schema.required(),
      })
      .when([], {
        is: () => checkIfAdult === true,
        then: (schema) => schema.isAdult('', 'dobDay'),
      }),
    dobYear: yup
      .number()
      .transformNaNtoNull()
      .min(1900, 'Please enter a year greater than 1900.')
      .max(new Date().getFullYear())
      .label('Year')
      .hasPastDate('The date must be in the past', 'dobYear')
      .hasValidDate('Date is invalid', 'dobYear')
      .dateHasRequiredFields('Required', 'dobYear')
      .when([], {
        is: () => required === true,
        then: (schema) => schema.required(),
      })
      .when([], {
        is: () => checkIfAdult === true,
        then: (schema) => schema.isAdult('', 'dobYear'),
      }),
    dobGeneral: yup.mixed().when([], {
      is: () => checkIfAdult === true,
      then: (schema) => schema.isAdult('You must be at least 18 years old to use MediFind.com', 'dobGeneral'),
    }),
  });
};
export const dobDateWithRequired = dobDateSchema(true);

export const dobDateWithCheckIfAdult = dobDateSchema(false, true);

export const dobDate = dobDateSchema();

export const formatDate = (year, month, day) => {
  if (!year || !month || !day) {
    return null;
  }
  return `${year}-` + `${month}`.padStart(2, '0') + '-' + `${day}`.padStart(2, '0');
};

export const Yup = yup;
