import { colorLookup } from '@medifind/utils';

import { SVG } from './SVG';
const IconHintSmall = (props) => {
  const { color } = props;
  const colorCode = colorLookup(color) ?? colorLookup('blue');
  return (
    <SVG width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke={colorCode}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25391 5.19976C5.39497 4.79876 5.6734 4.46062 6.03988 4.24524C6.40636 4.02985 6.83724 3.95112 7.25621 4.02298C7.67518 4.09485 8.0552 4.31267 8.32895 4.63787C8.60271 4.96307 8.75254 5.37467 8.75191 5.79976C8.75191 6.99976 6.95191 7.59976 6.95191 7.59976"
        stroke={colorCode}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="7.00002" cy="10" rx="0.6" ry="0.599999" fill={colorCode} />
    </SVG>
  );
};
export { IconHintSmall as default, IconHintSmall };
