import classNames from 'classnames';
import PropTypes from 'prop-types';
import { get, useFormContext } from 'react-hook-form';
import formsStyles from '@medifind/styles/components/form.module.scss';
import styles from './ErrorMessage.module.scss';

const splitLocalTime = (value) => {
  const utcArr = value?.split(':') || [];
  const [utcHours, utcMin] = utcArr;
  const today = new Date();
  // today.setUTCHours(parseInt(utcHours, 10));
  // today.setUTCMinutes(parseInt(utcMin, 10));
  today.setHours(parseInt(utcHours, 10));
  today.setMinutes(parseInt(utcMin, 10));
  let hour = today.getHours();
  const amPm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  const min = today.getMinutes().toString();
  // The format for  <input type="time" /> must be "HH:mm"
  const displayHour = hour?.length === 1 ? `0${hour}` : hour;
  const displayMin = min?.length === 1 ? `0${min}` : min;
  return `${[displayHour, displayMin].join(':')} ${amPm}`;
};

const toLocalTime = (value = '', time) => {
  if (value) {
    const timeArr = value?.split('-') || [];
    const openTime = timeArr[0] ? splitLocalTime(timeArr[0]) : '';
    const closeTime = timeArr[1] ? splitLocalTime(timeArr[1]) : '';
    return openTime && closeTime ? `${openTime}-${closeTime}` : '';
  }
};

const updatedVersion = ({ oldVersion, updatedOldVersion, newVersion, updatedNewVersion }) => {
  updatedOldVersion = toLocalTime(oldVersion, 'old');
  updatedNewVersion = toLocalTime(newVersion, 'new');
  return { uov: updatedOldVersion, unv: updatedNewVersion };
};

const getFeedbackMessage = ({ displayName, oldVersion, newVersion, syncTimeZone }) => {
  const dataIsArray = Array.isArray(oldVersion) || Array.isArray(newVersion);
  const arrayDiff = (oldVersion?.length ?? 0) - (newVersion?.length ?? 0);

  if (dataIsArray) {
    if (arrayDiff !== 0)
      return arrayDiff > 0
        ? `${displayName ? `${displayName} r` : 'R'}emoved ${arrayDiff} entr${arrayDiff === 1 ? 'y' : 'ies'}`
        : `${displayName ? `${displayName} a` : 'A'}dded ${-arrayDiff} entr${-arrayDiff === 1 ? 'y' : 'ies'}`;
    else return null;
  }

  let updatedOldVersion = null;
  let updatedNewVersion = null;
  if (syncTimeZone) {
    const { uov, unv } = updatedVersion({ oldVersion, updatedOldVersion, newVersion, updatedNewVersion });
    updatedOldVersion = uov;
    updatedNewVersion = unv;
  }
  if (oldVersion !== newVersion && newVersion !== undefined && !(oldVersion == null && newVersion == null)) {
    return `${displayName ? `${displayName} c` : 'C'}hanged from: ${(updatedOldVersion || oldVersion) ?? 'Nothing'} → ${
      (updatedNewVersion || newVersion) ?? 'Nothing'
    }`;
  }
  return null;
};

const ErrorMessage = ({
  displayName,
  name,
  formatDisplay,
  validationMessage,
  options,
  style = {},
  disableVersionMessage,
  disableErrorMessage,
  disableWhenFieldsHaveErrors,
  customError,
  syncTimeZone = false,
  isStringify = false,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const lookupDisplay = (value) => {
    if (value === undefined) return value;
    if (value !== null && typeof value === 'object' && Object.keys(value).length === 0) return null;
    if (formatDisplay) value = formatDisplay(value);
    if (options) {
      const foundOption = options.find((o) => o.value === value);
      return foundOption?.display ?? foundOption?.label;
    } else return value;
  };
  const { error, infoMessage, oldVersion, newVersion } = (Array.isArray(name) ? name : [name]).reduce((acc, name) => {
    acc.error = acc.error ?? get(errors, name)?.message;
    acc.infoMessage = acc.infoMessage ?? get(errors, `root.infoMessage.${name}`)?.message;
    if (!acc.oldVersion && !acc.newVersion) {
      acc.oldVersion = isStringify
        ? lookupDisplay(get(get(errors, `root.version`)?.version?.old, name))?.join(', ')
        : lookupDisplay(get(get(errors, `root.version`)?.version?.old, name));
      acc.newVersion = isStringify
        ? lookupDisplay(get(get(errors, `root.version`)?.version?.new, name))?.join(', ')
        : lookupDisplay(get(get(errors, `root.version`)?.version?.new, name));
    }
    return acc;
  }, {});

  const feedbackNotice = getFeedbackMessage({ displayName, oldVersion, newVersion, syncTimeZone });

  return (
    <div className={classNames(styles['container'])} style={style?.containerStyles}>
      {!disableWhenFieldsHaveErrors?.some((fieldName) => !!get(errors, fieldName)?.message) &&
        !disableErrorMessage &&
        (error || customError) && (
          <div className={classNames(formsStyles['feedback'], formsStyles['feedback--invalid'])} style={style?.feedback}>
            {error ?? customError}
          </div>
        )}
      {(infoMessage || validationMessage) && !error && (
        <div className={formsStyles['feedback']} style={style?.feedback}>
          {infoMessage ?? validationMessage}
        </div>
      )}
      {!disableVersionMessage && feedbackNotice ? (
        <div className={classNames(formsStyles['feedback'], formsStyles['feedback--notice'])} style={style?.feedback}>
          {feedbackNotice}
        </div>
      ) : null}
    </div>
  );
};

ErrorMessage.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  formatDisplay: PropTypes.func,
  validationMessage: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
  disableErrorMessage: PropTypes.bool,
  disableVersionMessage: PropTypes.bool,
  disableWhenFieldsHaveErrors: PropTypes.array,
  customError: PropTypes.string,
  syncTimeZone: PropTypes.bool,
};
export { ErrorMessage as default, ErrorMessage };
