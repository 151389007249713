import { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { get, /* useController, */ useFormContext } from 'react-hook-form';
import { What } from '@medifind/shared-basic-components';
import formsStyles from '@medifind/styles/components/form.module.scss';
import { ErrorMessage } from './ErrorMessage';

const InputGroup = ({
  type,
  name,
  onChange,
  onBlur,
  label,
  noHeader = false,
  placeholder,
  autoComplete,
  what,
  disabled,
  classes = {},
  onFocus,
  validationMessage,
  ...props
}) => {
  const {
    register,
    formState: { errors, disabledForm, isSubmitting },
  } = useFormContext();
  const error = get(errors, name);

  const inputValue = useRef();

  const handleOnChange = (event) => {
    inputValue.current = event.target.value;
    register(name).onChange(event);
  };

  return (
    <div
      className={classNames(
        formsStyles['form-group'],
        {
          [formsStyles['form-group--error']]: error,
        },
        classes.wrapper,
      )}
    >
      {!noHeader && (
        <label className={classNames(formsStyles['form-label'], classes.label)} htmlFor={name}>
          {classes?.labelSpan ? <span className={classes.labelSpan}>{label}</span> : label}
          {what && <What>{what}</What>}
        </label>
      )}

      <input
        {...(!isSubmitting || !inputValue.current
          ? register(name, {
              onChange,
              onBlur,
              setValueAs: (val) => (typeof val === 'string' ? val.trim() : val) || null,
            })
          : { value: inputValue.current })}
        onChange={handleOnChange}
        aria-label={label}
        id={name}
        type={type}
        name={name}
        disabled={disabled || disabledForm}
        className={classNames(formsStyles['form-control'], classes.input)}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onFocus={onFocus}
        {...props}
      />

      <ErrorMessage name={name} validationMessage={validationMessage} />
    </div>
  );
};

InputGroup.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'time']).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noHeader: PropTypes.bool,
  what: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validationMessage: PropTypes.string,
};
export { InputGroup as default, InputGroup };
